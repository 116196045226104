import { observer } from 'mobx-react-lite';
import { Flex, Input, Typography } from 'antd';

import style from './style.module.scss';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { FilteringDateType } from '../../filterReducer';


type Props = {
  filteringDate: FilteringDateType;
  onDateFilter: (value: FilteringDateType) => void;
};

// todo - can move to common
const View = ({ filteringDate, onDateFilter }: Props) => {

  const handleDateChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    const newDate = { ...filteringDate, [name]: value };
    onDateFilter(newDate);
  };

  return (
    <GrayWrap width="400px">
      <Flex className={style.datafilter__content}>
        <Typography className={style.datafilter__title}>Выбор дат</Typography>
        <Flex gap={5}>
          <Input
            name="dateFrom"
            value={filteringDate.dateFrom}
            type="datetime-local"
            onChange={(e) => handleDateChange(e)}
          />
          <Input
            name="dateTo"
            value={filteringDate.dateTo}
            type="datetime-local"
            onChange={(e) => handleDateChange(e)}
          />
        </Flex>
      </Flex>
    </GrayWrap>
  );
};

export const DataFilter = observer(View);