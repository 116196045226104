import { makeAutoObservable } from 'mobx';

import { ClearingDataType, getClearingDataParams } from '../../api/clearing-api/classes';
import { ClearingAPI } from '../../api/clearing-api/api';

class ClearingVM {
  data: ClearingDataType[] = [];

  getClearingData = async (props: getClearingDataParams) => {
    const propsWithISODate = {
      ...props,
      dateFrom: new Date(props.dateFrom).toISOString(),
      dateTo: new Date(props.dateTo).toISOString(),
    };

    const res = await ClearingAPI.getClearingData(propsWithISODate);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.statsList;
      }
    }
  };

  // createExTools = async (values: Values) => {
  //   const res = await ExToolsAPI.createAdress({
  //     ...(values as ExToolsDataType),
  //     input: false,
  //     output: false,
  //   });
  //   if (res.status === 201) {
  //     if (res.data) {
  //       this.getExTools();
  //     }
  //   }
  // };
  // editExTools = async (data: ExToolsDataType) => {
  //   // if (res.status === 201) {
  //   //   if (res.data) {
  //   //     this.getExTools();
  //   //   }
  //   // }
  // };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ClearingVM;
