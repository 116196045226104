import { claimStatusString, TClaimStatusString } from '../../api/exchanges-api/classes';
import { checkTypeF } from '../../common/helpers';
import { providers, TProviders } from '../../common/types';
import { TClearingFilters } from '../clearing/columns';

export const actionTypes = [
  'searchValue',
  'coinId',
  'coinFromId',
  'coinToId',
  'networkId',
  'networkFromId',
  'networkToId',
  'status',
  'providerName',
  'fromClearing',
  'filteringDate',
] as const;

export type IActionTypes = (typeof actionTypes)[number];

export type FilteringDateType = {
  dateFrom: string | undefined;
  dateTo: string | undefined;
};

type ActionType = {
  type: IActionTypes;
  value: TClaimStatusString | string;
  clearingFilters?: TClearingFilters;
  filteringDate?: FilteringDateType;
};

export type FiltersType = {
  dateTo: string | undefined;
  dateFrom: string | undefined;
  searchValue: string | undefined;
  coinId: string | undefined;
  coinFromId: string | undefined;
  coinToId: string | undefined;
  networkId: string | undefined;
  networkFromId: string | undefined;
  networkToId: string | undefined;
  status: TClaimStatusString | undefined;
  providerName: TProviders | undefined;
};

const dateToday = new Date().toISOString().slice(0, 10); // yyyy-MM-dd (Today Date)
const dateMounthAgo = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10); // yyyy-MM-dd (Mouth ago Date)

export const initialFilters: FiltersType = {
  dateTo: `${dateToday}T23:59`,
  dateFrom: `${dateMounthAgo}T00:00`,
  searchValue: undefined,
  coinId: undefined,
  coinFromId: undefined,
  coinToId: undefined,
  networkId: undefined,
  networkFromId: undefined,
  networkToId: undefined,
  status: undefined,
  providerName: undefined,
};

export const filterReducer = (filters: FiltersType, action: ActionType): FiltersType => {
  switch (action.type) {
    case 'searchValue': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, searchValue: value };
    }
    case 'coinId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, coinId: value };
    }
    case 'coinFromId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, coinFromId: value };
    }
    case 'coinToId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, coinToId: value };
    }
    case 'networkId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, networkId: value };
    }
    case 'networkFromId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, networkFromId: value };
    }
    case 'networkToId': {
      const value = action.value !== '' ? action.value : undefined;
      return { ...filters, networkToId: value };
    }
    case 'status': {
      // todo try to find more smart way to check type action.value
      // const value = action.value !== "" && ActionTypes.includes(action.value) ? action.status : 'ALL';
      const value =
        action.value !== '' && checkTypeF(claimStatusString, action.value)
          ? (action.value as TClaimStatusString)
          : undefined;
      return { ...filters, status: value };
    }
    case 'providerName': {
      const value =
        action.value !== '' && checkTypeF(providers, action.value) ? (action.value as TProviders) : undefined;
      return { ...filters, providerName: value };
    }
    case 'filteringDate': {
      const date = action?.filteringDate;
      if (date) {
        return { ...filters, ...date };
      }
      return { ...filters };
    }
    case 'fromClearing': {
      const value = action?.clearingFilters;
      if (value) {
        return {
          ...filters,
          dateFrom: value.dateFrom,
          dateTo: value.dateTo,
          coinFromId: value.coinFromId,
          coinToId: value.coinToId,
          networkFromId: value.networkFromId,
          networkToId: value.networkToId,
        };
      }
      return { ...filters };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
