import { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import { columns } from './columns';
import { DateFilter } from './components/DateFilter';
import { expandedRowRender } from './components/ExpandedTable';
import { Loader } from '../../common/components/Loader';

import style from './styles/style.module.scss';

import { ClearingDataType } from '../../api/clearing-api/classes';
import { ActionTypes, filterReducer, initialFilters } from './filterReducer';
import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../store/ModulesContextProvider';

export type FilteringDateType = {
  dateFrom: string;
  dateTo: string;
};

const dateToday = new Date().toISOString().slice(0, 10); // yyyy-MM-dd (Today Date)
const dateMounthAgo = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10); // yyyy-MM-dd (Mouth ago Date)

const View = () => {
  const { ClearingStore } = useModulesContext();

  const [loading, setLoading] = useState(true);

  const [filteringDate, setFilteringDate] = useState({
    dateFrom: `${dateMounthAgo}T00:00`,
    dateTo: `${dateToday}T23:59`,
  });

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const props = {
    ...filteringDate,
    ...filters,
  };

  useEffect(() => {
    ClearingStore.getClearingData(props).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClearingStore, filteringDate, filters]);

  function handleSelectFilter(value: string, key: ActionTypes) {
    dispatch({
      type: key,
      text: value,
    });
  }

  function handleDateFilter(value: FilteringDateType) {
    setFilteringDate(value);
  }

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header}>
          <h1>Клиринг</h1>
        </Header>
        <Content className={style.layout__content}>
          <Flex vertical gap={10}>
            <Flex vertical gap={5}>
              <FilterBar filters={filters} onFilter={handleSelectFilter} />
              <DateFilter filteringDate={filteringDate} onDateFilter={handleDateFilter} />
            </Flex>
            {loading ? (
              <Loader />
            ) : (
              <Table
                rowKey={(record: ClearingDataType) => `${record.instrumentFrom.id}+${record.instrumentTo.id}`}
                dataSource={ClearingStore.data}
                bordered
                scroll={{ x: 2000 }}
                columns={columns({...filters, ...filteringDate})}
                expandable={{ expandedRowRender }}
              />
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const Clearing = observer(View);