import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

export type TOption = {
  label: string;
  value: string;
};

type Props = {
  selectedValue: string | undefined;
  options: TOption[];
  onSelect: (value: string) => void;
  onSearch?: (value: string) => void;
};

const View = ({ selectedValue, options, onSelect, onSearch }: Props) => {
  const onChange = (value: string) => {
    const stringValue = value[0];
    onSelect(stringValue);
  };

  const handleSearch = (value: string) => {
    if (onSearch) {
      onSearch(value);
    }
  };

  // todo - task - what do this funcktion ?
  // Filter `option.label` match the user type `input`

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // const optionsData = options?.map((item) => ({ label: item.ticker, value: item.id })); //ex

  return (
    <Select
      style={{ width: '150px' }}
      mode="multiple"
      maxCount={1}
      showSearch
      onChange={onChange}    
      onSearch={handleSearch}
      filterOption={filterOption}
      options={options}
      value={selectedValue !== '' ? selectedValue : undefined}
    />
  );
};

export const SelectFilterTest = observer(View);
