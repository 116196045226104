import { TStatus } from '../../common/types';

export type ActionTypes =
  | 'searchValue'
  | 'coinId'
  | 'coinFromId'
  | 'coinToId'
  | 'networkId'
  | 'networkFromId'
  | 'networkToId'
  | 'activeStatus';

type ActionType = {
  type: ActionTypes;
  text?: string;
  status?: TStatus;
};

export type FiltersType = {
  searchValue: string | undefined;
  coinId: string | undefined;
  coinFromId: string | undefined;
  coinToId: string | undefined;
  networkId: string | undefined;
  networkFromId: string | undefined;
  networkToId: string | undefined;
  activeStatus: TStatus;
};

export const initialFilters: FiltersType = {
  searchValue: undefined,
  coinId: undefined,
  coinFromId: undefined,
  coinToId: undefined,
  networkId: undefined,
  networkFromId: undefined,
  networkToId: undefined,
  activeStatus: 'ALL',
};

export const filterReducer = (filters: FiltersType, action: ActionType): FiltersType => {
  switch (action.type) {
    case 'searchValue': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, searchValue: value };
    }
    case 'coinId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, coinId: value };
    }
    case 'coinFromId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, coinFromId: value };
    }
    case 'coinToId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, coinToId: value };
    }
    case 'networkId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, networkId: value };
    }
    case 'networkFromId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, networkFromId: value };
    }
    case 'networkToId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, networkToId: value };
    }
    case 'activeStatus': {
      const value = action.status ? action.status : 'ALL';
      return { ...filters, activeStatus: value };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
