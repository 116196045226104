import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';

import Router from './modules/router';
import { ModulesContextProvider } from './store/ModulesContextProvider';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './App.css';

function App() {
  
  // let kkk = 'NOOOO';
  // const wAny = window as any;
  // if (wAny.Telegram && wAny.Telegram.WebApp && wAny.Telegram.WebApp.initData) {
  //   kkk = wAny.Telegram.WebApp.initData;
  //   // CoinNetworkStore.kekTest(wAny.Telegram.WebApp.initData)
  // }

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#6f26cd',
          // Alias Token
          colorBgContainer: '#7736cb13',
        },
      }}
    >
      <ModulesContextProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Router />
      </ModulesContextProvider>
    </ConfigProvider>
  );
}

export default App;