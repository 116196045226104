import { useContext, useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { columns } from './columns';
import { Loader } from '../../common/components/Loader';
import { getExPairsParams } from '../../api/exchangePair-api/classes';
import { TStatus } from '../../common/types';
import { ActionTypes, filterReducer, initialFilters } from './filterReducer';

import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../store/ModulesContextProvider';

const View = () => {
  const { ExchangePairStore } = useModulesContext();
  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    perPage: 10,
  });
  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const getDataProps: getExPairsParams = { ...filters, ...pageInfo } as getExPairsParams;

  useEffect(() => {
    const URLQueryParamCoin = URLSearchParams.get('coin');
    if (URLQueryParamCoin !== null) {
      dispatch({
        type: 'coinId',
        text: URLQueryParamCoin,
      });
      setURLSearchParams({});
    }
    const URLQueryParamNetwork = URLSearchParams.get('network');
    if (URLQueryParamNetwork !== null) {
      dispatch({
        type: 'networkId',
        text: URLQueryParamNetwork,
      });
      setURLSearchParams({});
    }
    ExchangePairStore.getData(getDataProps).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExchangePairStore, URLSearchParams, pageInfo, filters]);

  function handleSelectFilter(value: string, filterType: ActionTypes) {
    // todo update all (searchFeild & StatusRadioBox) components usage then remove "?" from key prop. (add handlers not state lifting)
    dispatch({
      type: filterType,
      text: value,
    });
  }

  function handleStatusFilter(value: TStatus) {
    dispatch({
      type: 'activeStatus',
      status: value,
    });
  }

  function handleSearchFilter(value: string) {
    dispatch({
      type: 'searchValue',
      text: value,
    });
  }

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header}>
          <h1>Обменные пары</h1>
        </Header>
        <Content className={style.layout__content}>
          <Flex vertical gap={20}>
            <FilterBar
              filters={filters}
              onFilter={handleSelectFilter}
              onChangeStatus={handleStatusFilter}
              onSearchFilter={handleSearchFilter}
            />
            {loading ? (
              <Loader />
            ) : (
              <Table
                rowKey="id"
                dataSource={ExchangePairStore?.data}
                columns={columns()}
                bordered
                pagination={{
                  total: ExchangePairStore.count,
                  showSizeChanger: true,
                  pageSize: pageInfo.perPage,
                  onChange(page, pageSize) {
                    setPageInfo({
                      page: page,
                      perPage: pageSize,
                    });
                  },
                }}
              />
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const ExchangePairs = observer(View);
