import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { columns } from './columns';
import { SearchField } from '../../common/components/SearchField';
import { getExToolsParams } from '../../api/exTools-api/classes';
import { FilterType, SelectFilter } from '../../common/components/SelectFilter';
import { TStatus } from '../../common/types';
import { StatusRadioBox } from '../../common/components/StatusRadioBox';
import { Loader } from '../../common/components/Loader';
import { useModulesContext } from '../../store/ModulesContextProvider';

const View = () => {
  const { ExToolsStore } = useModulesContext();
  const [URLSearchParams, setURLSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageValue, setPerPageValue] = useState(10);

  const [searchValue, setSearchValue] = useState('');

  const [selectedCoin, setSelectedCoin] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('');

  const [inStatus, setInStatus] = useState<TStatus>('ALL');
  const [outStatus, setOutStatus] = useState<TStatus>('ALL');

  const props: getExToolsParams = {
    pageNumber: currentPage,
    numberOfElems: perPageValue,
    searchValue: searchValue === '' ? undefined : searchValue,
    coinId: selectedCoin === '' ? undefined : selectedCoin,
    networkId: selectedNetwork === '' ? undefined : selectedNetwork,
    inStatus: inStatus,
    outStatus: outStatus,
  };

  useEffect(() => {
    const URLQueryParamCoin = URLSearchParams.get('coin');
    if (URLQueryParamCoin !== null) {
      setSelectedCoin(URLQueryParamCoin);
      setURLSearchParams({});
    }
    const URLQueryParamNetwork = URLSearchParams.get('network');
    if (URLQueryParamNetwork !== null) {
      setSelectedNetwork(URLQueryParamNetwork);
      setURLSearchParams({});
    }
    ExToolsStore.getExTools(props).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ExToolsStore,
    currentPage,
    perPageValue,
    searchValue,
    selectedCoin,
    selectedNetwork,
    inStatus,
    outStatus,
    URLSearchParams,
    setURLSearchParams,
  ]);

  function handleSelectFilter(value: string, key: string) {
    switch (key) {
      case 'Монета':
        setSelectedCoin(value);
        break;
      case 'Сеть':
        setSelectedNetwork(value);
        break;
    }
  }

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header}>
          <h1>Инструменты</h1>
        </Header>
        <Content className={style.layout__content}>
          <Flex vertical gap={10} className={style.content_container}>
            <Flex gap={10}>
              <SearchField searchValue={searchValue} setSearchValue={setSearchValue} fieldName="Ticker" />
              <SelectFilter
                titleName="Монета"
                paramName={FilterType.COIN}
                selectedValue={selectedCoin}
                // setSelectedValue={setSelectedCoin}
                onFilter={handleSelectFilter}
              />
              <SelectFilter
                titleName="Сеть"
                paramName={FilterType.NETWORK}
                selectedValue={selectedNetwork}
                // setSelectedValue={setSelectedNetwork}
                onFilter={handleSelectFilter}
              />
            </Flex>
            <Flex gap={10}>
              <StatusRadioBox title="Ввод" statusValue={inStatus} setStatusValue={setInStatus} />
              <StatusRadioBox title="Вывод" statusValue={outStatus} setStatusValue={setOutStatus} />
            </Flex>
            {loading ? (
              <Loader />
            ) : (
              <Table
                rowKey="id"
                dataSource={ExToolsStore?.data}
                columns={columns()}
                bordered
                pagination={{
                  total: ExToolsStore.count,
                  showSizeChanger: true,
                  pageSize: perPageValue,
                  onChange(page, pageSize) {
                    setCurrentPage(page);
                    setPerPageValue(pageSize);
                  },
                }}
              />
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const ExTools = observer(View);
