import { API } from '..';
import { CoinNetworkResponse, getCoinNetworksParams } from './classes';

const PATHS = {
  COINNETWORK: '/api/networks',
  // COINNETWORK_ID: (id: string) => `${PATHS.COINNETWORK}/${id}`,
};

export const CoinNetworksAPI = {
  getCoinNetworks: async (props: getCoinNetworksParams) => {
    return await API.get<CoinNetworkResponse>({
      url: PATHS.COINNETWORK,
      params: { ...props },
    });
  },

  // createAdress: async (data: ExToolsDataType) => {
  //   return await API.post({
  //     url: PATHS.EXTOOLS,
  //     data: { ...data },
  //   });
  // },

  //   switchStateAdress: async (id: string, data: ExToolsDataType) => {
  //     return await API.put({
  //       url: PATHS.ADRESS_ID(id),
  //       data: { ...data },
  //     });
  //   },
};
