import { checkTypeF } from '../../common/helpers';
import { providers, rateTypes, TProviders, TRateTypes } from '../../common/types';

export type ActionTypes =
  | 'coinFromId'
  | 'coinToId'
  | 'networkFromId'
  | 'networkToId'
  | 'providerName'
  | 'rateType'
  | 'date';

type ActionType = {
  type: ActionTypes;
  text: string;
};

export type FiltersType = {
  coinFromId: string | undefined;
  coinToId: string | undefined;
  networkFromId: string | undefined;
  networkToId: string | undefined;
  providerName: TProviders | undefined;
  rateType: TRateTypes | undefined;
};

export const initialFilters: FiltersType = {
  coinFromId: undefined,
  coinToId: undefined,
  networkFromId: undefined,
  networkToId: undefined,
  providerName: undefined,
  rateType: undefined,
};

export const filterReducer = (filters: FiltersType, action: ActionType): FiltersType => {
  switch (action.type) {
    case 'coinFromId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, coinFromId: value };
    }
    case 'coinToId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, coinToId: value };
    }
    case 'networkFromId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, networkFromId: value };
    }
    case 'networkToId': {
      const value = action.text !== '' ? action.text : undefined;
      return { ...filters, networkToId: value };
    }
    case 'providerName': {
      const value = action.text !== '' && checkTypeF(providers, action.text) ? (action.text as TProviders) : undefined;
      return { ...filters, providerName: value };
    }
    case 'rateType': {
      const value = action.text !== '' && checkTypeF(rateTypes, action.text) ? (action.text as TRateTypes) : undefined;
      return { ...filters, rateType: value };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
