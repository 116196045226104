import { API } from '..';
import {
  getExchangesParams,
  GetExchangesResponse,
  GetOneExchangeResponse,
  postAddCommentToClaimProps,
} from './classes';

const PATHS = {
  EXCHANGES: '/api/claims',
  EXCHANGESONE: '/api/claims/one',
  ADDCOMMENT: '/api/claims/comment-add',
};

export const ExchangesAPI = {
  getExchanges: async (props: getExchangesParams) => {
    return await API.get<GetExchangesResponse>({
      url: PATHS.EXCHANGES,
      params: { ...props },
    });
  },

  getOneExchange: async (claimID: string) => {
    return await API.get<GetOneExchangeResponse>({
      url: PATHS.EXCHANGESONE,
      params: { claimId: claimID },
    });
  },

  addCommentToClaim: async (props: postAddCommentToClaimProps) => {
    return await API.post({
      url: PATHS.ADDCOMMENT,
      data: {
        ...props,
      },
    });
  },
};
