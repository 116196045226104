import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { columns } from './columns';
import { Loader } from '../../common/components/Loader';
import { useModulesContext } from '../../store/ModulesContextProvider';

const View = () => {

  const { GeneralSettingsStore } = useModulesContext();
  const { getSettingsList, toggleSettingKey, data } = GeneralSettingsStore;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSettingsList().then(() => setLoading(false));
  }, [GeneralSettingsStore, getSettingsList]);

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header}>
          <h1>Основные настройки</h1>
          <Flex
            justify="space-between"
            style={{
              width: 250,
              padding: '10px',
              border: '1px solid #dddddd',
              borderRadius: '10px',
            }}
          >
            <div></div>
            {/* <Typography style={{ fontWeight: 800 }}>Активация обменов</Typography>
            <Switch checkedChildren="ВКЛ" unCheckedChildren="ВЫКЛ" defaultChecked checked={true} /> */}
          </Flex>
        </Header>
        <Content className={style.layout__content}>
          {loading ? (
            <Loader />
          ) : (
            <Table rowKey="id" dataSource={data} columns={columns({ toggleSettingKey })} bordered />
          )}
        </Content>
      </Layout>
    </>
  );
};

export const GeneralSettings = observer(View);