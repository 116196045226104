import { API } from '..';
import { GetSetiingsResponse, ToggleSettingsProps } from './classes';

const PATHS = {
  SETTINGS: '/api/global-settings',
  TOGGLESETTINGS: '/api/global-settings/toggle-active',
};

export const generalSettingsAPI = {
  getSettingsList: async () => {
    return await API.get<GetSetiingsResponse>({
      url: PATHS.SETTINGS,
    });
  },

  toggleSettingKey: async (props: ToggleSettingsProps) => {
    return await API.post<GetSetiingsResponse>({
      url: PATHS.TOGGLESETTINGS,
      data: {
        ...props,
      },
    });
  },
};
