import { API } from '..';
import { GetExToolByIDResponse, getExToolsParams, GetExToolsResponse } from './classes';

const PATHS = {
  EXTOOLS: '/api/instruments',
  EXTOOLONE: '/api/instruments/one',
};

export const ExToolsAPI = {
  getExTools: async (props: getExToolsParams) => {    
    return await API.get<GetExToolsResponse>({
      url: PATHS.EXTOOLS,
      params: {
        ...props,
      },
    });
  },

  getExToolByID: async (id: string) => {
    return await API.get<GetExToolByIDResponse>({
      params: { instrumentId: id },
      url: PATHS.EXTOOLONE,
    });
  },

  // createAdress: async (data: ExToolsDataType) => {
  //   return await API.post({
  //     url: PATHS.EXTOOLS,
  //     data: { ...data },
  //   });
  // },
  //   updateAdress: async (id: string, data: ExToolsDataType) => {
  //     return await API.put({
  //       url: PATHS.ADRESS_ID(id),
  //       data: { ...data },
  //     });
  //   },
  //   switchStateAdress: async (id: string, data: ExToolsDataType) => {
  //     return await API.put({
  //       url: PATHS.ADRESS_ID(id),
  //       data: { ...data },
  //     });
  //   },
};
