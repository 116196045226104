import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Select, Typography } from 'antd';
import { useModulesContext } from '../../../store/ModulesContextProvider';

export enum FilterType {
  COIN = 'coin',
  NETWORK = 'network',
}

type Props = {
  titleName: string;
  paramName: FilterType;
  selectedValue: string | undefined;
  onFilter: (valye: string, key: string) => void;
};

const View = ({ selectedValue, paramName, titleName, onFilter }: Props) => {
  const { CoinsStore, CoinNetworkStore } = useModulesContext();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (paramName === 'coin') {
      CoinsStore.getCoins({ searchValue: searchValue !== '' ? searchValue : undefined });
    }
    if (paramName === 'network') {
      CoinNetworkStore.getCoinNetworks({ searchValue: searchValue !== '' ? searchValue : undefined });
    }
  }, [CoinsStore, CoinNetworkStore, searchValue, paramName]);

  const onChange = (value: string) => {
    const stringValue = value[0];
    onFilter(stringValue, titleName);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  // todo - task - what do this funcktion ?
  // Filter `option.label` match the user type `input`

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  let data;
  let placeholderValue;

  if (paramName === 'coin') {
    data = CoinsStore.data;
    placeholderValue = 'Монета';
  }

  if (paramName === 'network') {
    data = CoinNetworkStore.data;
    placeholderValue = 'Сеть';
  }

  const optionsData = data?.map((item) => ({ label: item.ticker, value: item.id })); //ex

  return (
    <div>
      <Typography>{titleName}</Typography>
      <Select
        style={{ width: '150px' }}
        mode="multiple"
        maxCount={1}
        showSearch
        placeholder={placeholderValue}
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={optionsData}
        value={selectedValue !== '' ? selectedValue : undefined}
        optionFilterProp="ticker"
      />
    </div>
  );
};

export const SelectFilter = observer(View);
