import { ExPairsDataType } from '../../../../../../../api/exchangePair-api/classes';
import { ChangellyClaimDataType } from '../../../../../../../api/exchanges-api/classes';

export enum CurrencyType {
  EXPECTED = 'expected',
  REAL = 'real',
}

export const getCurrency = (
  type: CurrencyType,
  changelly_claim: ChangellyClaimDataType,
  exchange_pair: ExPairsDataType
) => {
  if (type === CurrencyType.EXPECTED) {
    return (
      `${changelly_claim.amount_expected_from} ` +
      `${exchange_pair.instrument_from.coin.ticker} (${exchange_pair.instrument_from.network.ticker})` +
      `  =  ` +
      `${changelly_claim.amount_expected_to} ` +
      `${exchange_pair.instrument_to.coin.ticker} (${exchange_pair.instrument_to.network.ticker})`
    );
  }
  if (type === CurrencyType.REAL) {
    return (
      `${changelly_claim.amount_from} ` +
      `${exchange_pair.instrument_from.coin.ticker} (${exchange_pair.instrument_from.network.ticker})` +
      `  =  ` +
      `${changelly_claim.amount_to} ` +
      `${exchange_pair.instrument_to.coin.ticker} (${exchange_pair.instrument_to.network.ticker})`
    );
  }
};
