import { observer } from 'mobx-react-lite';
import { Flex, Typography } from 'antd';

import style from '../../style.module.scss';
import { GrayWrap } from '../../../../../../common/components/GrayWrap';
import { InfoElement } from '../common/InfoElement';
import { CopyHashButton } from './components/CopyHashButton';
import { useModulesContext } from '../../../../../../store/ModulesContextProvider';

const View = () => {
  const { ExchangesStore } = useModulesContext();

  const { changelly_claim, exchange_pair } = ExchangesStore.oneClaimData;

  return (
    <>
      <Flex className={style.info_section}>
        {changelly_claim && changelly_claim.payin_hash !== '' && (
          <GrayWrap>
            <Typography className={style.info_block_title}>Входящая транзакция</Typography>
            <InfoElement title="Адрес получения" value={`${changelly_claim.payin_address}`} />
            <InfoElement
              title={'payin_extra_id_name'}
              value={changelly_claim.payin_extra_id === '' ? 'None' : `${changelly_claim.payin_extra_id}`}
            />
            <Flex className={style.info__raw}>
              <InfoElement title="Hash транзакции" element={<CopyHashButton value={changelly_claim.payin_hash} />} />
              <InfoElement
                title="Сумма"
                value={`${changelly_claim.amount_from} ${exchange_pair.instrument_from.coin.ticker}`}
              />
            </Flex>
          </GrayWrap>
        )}
        {changelly_claim && changelly_claim.payout_hash !== '' && (
          <div style={{ width: '100%' }}>
            <GrayWrap>
              <Typography className={style.info_block_title}>Исходящая транзакция</Typography>
              <InfoElement title="Адрес получения" value={`${changelly_claim.payout_address}`} />
              <InfoElement
                title={'payout_extra_id_name'}
                value={changelly_claim.payout_extra_id === '' ? `None` : `${changelly_claim.payout_extra_id}`}
              />
              <Flex className={style.info__raw}>
                <InfoElement title="Hash транзакции" element={<CopyHashButton value={changelly_claim.payout_hash} />} />
                <InfoElement
                  title="Сумма"
                  value={`${changelly_claim.amount_to} ${exchange_pair.instrument_to.coin.ticker}`}
                />
              </Flex>
            </GrayWrap>
          </div>
        )}
        {changelly_claim && changelly_claim.refund_hash !== '' && (
          <div style={{ width: '100%' }}>
            <GrayWrap>
              <Typography className={style.info_block_title}>Возвратная транзакция</Typography>
              <InfoElement title="Адрес возврата" value={`${changelly_claim.refund_address}`} />
              <Flex className={style.info__raw}>
                <InfoElement title="Hash транзакции" element={<CopyHashButton value={changelly_claim.refund_hash} />} />
                <InfoElement
                  title="Сумма"
                  value={`${changelly_claim.amount_to} ${exchange_pair.instrument_to.coin.ticker}`}
                />
              </Flex>
            </GrayWrap>
          </div>
        )}
      </Flex>
    </>
  );
};

export const ExTransactionDetails = observer(View);
