import { Button, Flex, TableColumnsType, Typography } from 'antd';
import { Link } from 'react-router-dom';

import style from './styles/style.module.scss';

import { ClaimDataType } from '../../api/exchanges-api/classes';
import { getColor, getDate } from '../../common/helpers';

export const columns = (): TableColumnsType<ClaimDataType> => [
  {
    title: 'ID',
    render: (_: any, record: ClaimDataType) => (
      <>
        <Typography>{record.id}</Typography>
      </>
    ),
  },
  {
    title: 'Направление обмена',
    render: (_: any, record: ClaimDataType) => (
      <>
        <Flex style={{ gap: '8px' }}>
          <div>
            <Flex>
              <img
                className={style.crypto_icon}
                src={record.exchange_pair.instrument_from.image_url}
                alt={record.exchange_pair.instrument_from.name}
              />
              <Typography
                style={{ marginLeft: '5px' }}
              >{`${record.exchange_pair?.instrument_from.coin.ticker}_${record.exchange_pair?.instrument_from.network.ticker}`}</Typography>
            </Flex>
          </div>
          <Typography>{'-'}</Typography>
          <div>
            <Flex>
              <img
                className={style.crypto_icon}
                src={record.exchange_pair.instrument_to.image_url}
                alt={record.exchange_pair.instrument_to.name}
              />
              <Typography
                style={{ marginLeft: '5px' }}
              >{`${record.exchange_pair.instrument_to.coin.ticker}_${record.exchange_pair.instrument_to.network.ticker}`}</Typography>
            </Flex>
          </div>
        </Flex>
      </>
    ),
  },
  {
    title: 'expected_from',
    render: (_: any, record: ClaimDataType) => {
      const sellValue = record.changelly_claim?.amount_expected_from;
      return (
        <>
          <Typography>{sellValue ? sellValue : 'No data'}</Typography>
        </>
      );
    },
  },
  {
    title: 'expected_to',
    render: (_: any, record: ClaimDataType) => {
      const sellValue = record.changelly_claim?.amount_expected_to;
      return (
        <>
          <Typography>{sellValue ? sellValue : 'No data'}</Typography>
        </>
      );
    },
  },
  {
    title: 'real_from',
    render: (_: any, record: ClaimDataType) => {
      const sellValue = record.changelly_claim?.amount_from;
      return (
        <>
          <Typography>{sellValue ? sellValue : 'No data'}</Typography>
        </>
      );
    },
  },
  {
    title: 'real_to',
    render: (_: any, record: ClaimDataType) => {
      const sellValue = record.changelly_claim?.amount_to;
      return (
        <>
          <Typography>{sellValue ? sellValue : 'No data'}</Typography>
        </>
      );
    },
  },
  {
    title: 'Статус',
    render: (_: any, record: ClaimDataType) => {
      const [firstC, secondC, thirdC, fourthC] = getColor(record.status);
      return (
        <>
          <Flex gap={3}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${firstC}`,
                borderRadius: '50%',
              }}
            ></div>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${secondC}`,
                borderRadius: '50%',
              }}
            ></div>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${thirdC}`,
                borderRadius: '50%',
              }}
            ></div>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${fourthC}`,
                borderRadius: '50%',
              }}
            ></div>
          </Flex>
        </>
      );
    },
  },
  {
    title: 'Дата',
    render: (_: any, record: ClaimDataType) => {
      const startDate = getDate(record.claim_event_list[0].created_at);
      return (
        <>
          <Typography>{`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}</Typography>
        </>
      );
    },
  },
  {
    title: 'Детали',
    render: (_: any, record: ClaimDataType) => (
      <>
        <Link to={`/exchanges/${record.id}`}>
          <Button>Детали</Button>
        </Link>
      </>
    ),
  },
];
