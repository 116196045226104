import { observer } from 'mobx-react-lite';
import { Typography } from 'antd';

import { IActionTypes } from '../../filterReducer';
import { SelectFilterTest, TOption } from '../../../../common/components/SelectFilterTest';


type Props = {
  titleName: string;
  actionType: IActionTypes;
  selectedValue: string | undefined;
  onFilter: (value: string, key: IActionTypes) => void;
  options: TOption[];
  onSearch: (value: string) => void;
};

const View = ({ selectedValue, actionType, titleName, onFilter, onSearch, options }: Props) => {
  
  function handleSelect(value: string) {
    onFilter(value, actionType);
  }

  return (
    <div>
      <Typography>{titleName}</Typography>
      <SelectFilterTest selectedValue={selectedValue} options={options} onSelect={handleSelect} onSearch={onSearch} />
    </div>
  );
};

export const WrapperSelectFilter = observer(View);
