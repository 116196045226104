import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex } from 'antd';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { WrapperSelectFilter } from '../WrapperSelectFilter';
import { ActionTypes, FiltersType } from '../../filterReducer';
import { SearchField } from '../../../../common/components/SearchField';
import { StatusRadioBox } from '../../../../common/components/StatusRadioBox';
import { TStatus } from '../../../../common/types';
import { useModulesContext } from '../../../../store/ModulesContextProvider';

type Props = {
  filters: FiltersType;
  onFilter: (value: string, key: ActionTypes) => void;
  onChangeStatus: (value: TStatus) => void;
  onSearchFilter: (value: string) => void;
};

const View = ({ filters, onFilter, onChangeStatus, onSearchFilter }: Props) => {
  const { CoinsStore, CoinNetworkStore } = useModulesContext();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    CoinsStore.getCoins({ searchValue: searchValue !== '' ? searchValue : undefined });
    CoinNetworkStore.getCoinNetworks({ searchValue: searchValue !== '' ? searchValue : undefined });
  }, [CoinsStore, CoinNetworkStore, searchValue]);

  function handleSearch(value: string) {
    setSearchValue(value);
  }

  const coinData = CoinsStore.data.map((el) => ({ label: el.ticker, value: el.id }));
  const networkData = CoinNetworkStore.data.map((el) => ({ label: el.ticker, value: el.id }));

  return (
    <Flex vertical gap={10}>
      <GrayWrap width="1150px">
        <Flex justify="space-around">
          <SearchField fieldName="Поиск" searchValue={filters.searchValue} setSearchValue={onSearchFilter} />
          <WrapperSelectFilter
            titleName="Монета 1|2"
            actionType="coinId"
            selectedValue={filters.coinId}
            onFilter={onFilter}
            options={coinData}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Монета инструмента 1"
            actionType="coinFromId"
            selectedValue={filters.coinFromId}
            options={coinData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Монета инструмента 2"
            actionType="coinToId"
            selectedValue={filters.coinToId}
            options={coinData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />

          <WrapperSelectFilter
            titleName="Сеть 1|2"
            actionType="networkId"
            selectedValue={filters.networkId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Сеть инструмента 1"
            actionType="networkFromId"
            selectedValue={filters.networkFromId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Сеть инструмента 2"
            actionType="networkToId"
            selectedValue={filters.networkToId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
        </Flex>
      </GrayWrap>
      <StatusRadioBox title="ВКЛ/ВЫКЛ" statusValue={filters.activeStatus} setStatusValue={onChangeStatus} />
    </Flex>
  );
};

export const FilterBar = observer(View);
