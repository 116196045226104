import { API } from '..';
import { getClearingDataParams, GetClearingDataResponse } from './classes';

const PATHS = {
  CLEARING_STATS: '/api/clearing/stats',
};

export const ClearingAPI = {
  getClearingData: async (props: getClearingDataParams) => {
    return await API.get<GetClearingDataResponse>({
      url: PATHS.CLEARING_STATS,
      params: { ...props },
    });
  },
};
