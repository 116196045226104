import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { MenuProps, Menu, Layout as AntdLayout } from 'antd';
import Sider from 'antd/es/layout/Sider';

import style from './style.module.scss';

import { ReactComponent as CoinNetworkIcon } from '../assets/icon/carbon.svg';
import { ReactComponent as CoinIcon } from '../assets/icon/bag.svg';
import { ReactComponent as ToolsIcon } from '../assets/icon/clarity.svg';
import { ReactComponent as MoneyIcon } from '../assets/icon/money.svg';
import { ReactComponent as ExchangesIcon } from '../assets/icon/exchange.svg';
import { ReactComponent as ClearingIcon } from '../assets/icon/clearing.svg';
import { ReactComponent as GenSettingIcon } from '../assets/icon/setting.svg';

type MenuItem = Required<MenuProps>['items'][number];

type TGetCurrentMenuItem = {
  label: string;
  key: string;
  icon: JSX.Element;
};

function getCurrentMenuItem(menuItemsArr: TGetCurrentMenuItem[], path: string) {
  for (let i = menuItemsArr.length - 1; i >= 0; i--) {
    const elem = menuItemsArr[i];
    if (path.includes(elem.key)) {
      return elem.key;
    }
  }
  return '';
}

function getItem(label: string, key: string, icon?: React.ReactNode): MenuItem {
  return {
    key,
    label,
    icon,
  } as MenuItem;
}

const menuItemsArr = [
  { label: 'Сети', key: '/', icon: <CoinNetworkIcon /> },
  { label: 'Монеты', key: '/coins', icon: <CoinIcon /> },
  { label: 'Инструменты', key: '/extools', icon: <ToolsIcon /> },
  { label: 'Обменные пары', key: '/expairs', icon: <MoneyIcon /> },
  { label: 'Обмены', key: '/exchanges', icon: <ExchangesIcon /> },
  { label: 'Клиринг', key: '/clearing', icon: <ClearingIcon /> },
  { label: 'Настройки', key: '/gensettings', icon: <GenSettingIcon /> },
];

const items: MenuItem[] = menuItemsArr.map((el) => getItem(el.label, el.key, el.icon));

const Layout = observer(() => {
  const [collapsed, setCollapsed] = useState(false);
  const currentPathName = window.location.pathname;

  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <AntdLayout className={style.layout_main} hasSider>
        <Sider className={style.sider} onCollapse={() => toggleCollapsed()} collapsible collapsed={collapsed}>
          <Menu
            className={style.menu}
            selectedKeys={[getCurrentMenuItem(menuItemsArr, currentPathName)]}
            mode="inline"
            inlineCollapsed={collapsed}
            items={items}
            onClick={(key) => {
              navigate(key.keyPath[0]);
            }}
          />
        </Sider>
        <Outlet />
      </AntdLayout>
    </>
  );
});

export default Layout;