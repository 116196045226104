import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex, Typography } from 'antd';

import style from './style.module.scss';

import { GrayWrap } from '../../../../../../common/components/GrayWrap';
import { getDate } from '../../../../../../common/helpers';
import { InfoElement } from '../common/InfoElement';
import { TimeWaitingInfo } from './components/TimeWaitingInfo';
import { CurrencyType, getCurrency } from './helpers';
import JSONColapse from '../../../JSONColapse';
import { useModulesContext } from '../../../../../../store/ModulesContextProvider';

const View = () => {
  const { id } = useParams<string>(); // useing ti grab data

  const { ExchangesStore } = useModulesContext();

  const { changelly_claim, provider_name, exchange_pair, claim_event_list, status, rate_type, frontend_id } =
    ExchangesStore.oneClaimData;

  const createdDate = getDate(claim_event_list[0]?.created_at);

  return (
    <GrayWrap>
      <Flex style={{ flexDirection: 'column' }} gap={20}>
        <Flex className={style.details}>
          <Flex className={style.details__part}>
            <Typography className={style.info_block_title}>Детали заявки</Typography>
            <Flex gap={40}>
              <div className={style.infoDetail_container} style={{ width: 400, padding: '16px 10px 16px 10px' }}>
                <InfoElement title="ID номер" value={id} />
                <InfoElement title="Frontend_id" value={frontend_id} />
                <InfoElement
                  title="Дата создания (local time)"
                  value={`${createdDate?.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`}
                />
              </div>
              <div className={style.infoDetail_container} style={{ width: 400, padding: '16px 10px 16px 10px' }}>
                <InfoElement
                  title="Заявленный курс"
                  value={
                    changelly_claim ? getCurrency(CurrencyType.EXPECTED, changelly_claim, exchange_pair) : 'No Data'
                  }
                />
                <InfoElement
                  title="Фактический курс"
                  value={
                    changelly_claim && changelly_claim.payin_hash !== '' && changelly_claim.payout_hash !== ''
                      ? getCurrency(CurrencyType.REAL, changelly_claim, exchange_pair)
                      : 'No Data'
                  }
                />
              </div>
            </Flex>

            <div className={style.infoDetail_container} style={{ width: 600, padding: '16px 10px 16px 10px' }}>
              <Flex className={style.info__external} gap={60}>
                <Flex gap={20}>
                  <div>
                    <InfoElement title="Имя провайдера" value={provider_name} />
                    <InfoElement
                      title="ID провайдера"
                      value={changelly_claim?.changelly_id ? changelly_claim.changelly_id : 'No Data'}
                    />
                  </div>
                  <div>
                    <InfoElement
                      title="Коммисия"
                      value={changelly_claim?.total_fee ? changelly_claim.total_fee : 'No Data'}
                    />
                    <InfoElement
                      title="Коммисия сети"
                      value={changelly_claim?.network_fee ? changelly_claim.network_fee : 'No Data'}
                    />
                  </div>
                  <div>
                    <InfoElement
                      title="Коммисия наша"
                      value={changelly_claim?.api_extra_fee ? `${changelly_claim.api_extra_fee}%` : 'No Data'}
                    />
                    <InfoElement
                      title="Коммисия провайдера"
                      value={changelly_claim?.changelly_fee ? `${changelly_claim.changelly_fee}%` : 'No Data'}
                    />
                  </div>
                </Flex>
              </Flex>
            </div>
            <div className={style.infoDetail_container} style={{ width: 500, padding: '16px 10px 16px 10px' }}>
              <Flex gap={20}>
                <InfoElement title="Статус заявки" value={status} />
                <TimeWaitingInfo eventList={claim_event_list} />
                <InfoElement title="Тип курса обмена" value={rate_type} />
              </Flex>
            </div>
            {/* <div className={style.info__darknet}>
              <Typography>{`Darknet marketplace: ${'xx%'}`}</Typography>
            </div> */}
          </Flex>
        </Flex>
        <JSONColapse response={ExchangesStore.responseGetOneClaimDataField} />
      </Flex>
    </GrayWrap>
  );
};

export const ExDetails = observer(View);
