import { makeAutoObservable } from 'mobx';
import { CoinsDataType, getCoinsParams } from '../../api/coins-api/classes';
import { CoinsAPI } from '../../api/coins-api/api';

class CoinsVM {
  data: CoinsDataType[] = [];
  count: number = 0;

  getCoins = async (props: getCoinsParams) => {
    const res = await CoinsAPI.getCoins(props);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default CoinsVM;
