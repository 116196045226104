import { makeAutoObservable } from 'mobx';
import { ClaimDataType, getExchangesParams, postAddCommentToClaimProps } from '../../api/exchanges-api/classes';
import { ExchangesAPI } from '../../api/exchanges-api/api';

class ExchangesVM {
  data: ClaimDataType[] = [];
  count: number = 0;
  responseGetOneClaimDataField = '';
  oneClaimData: ClaimDataType = {} as ClaimDataType;
  // todo - change name of function getExchanges to getData like another function
  getExchanges = async (props: getExchangesParams) => {
    //todo creato more smart function to add iso data
    let propsWithISODate;
    if (props.dateFrom && props.dateTo) {
      propsWithISODate = {
        ...props,
        dateFrom: new Date(props.dateFrom).toISOString(),
        dateTo: new Date(props.dateTo).toISOString(),
      };
    }
    propsWithISODate = props;

    const res = await ExchangesAPI.getExchanges(propsWithISODate);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
    // todo this one vm methot which return response
    return res;
  };

  getOneExchange = async (exchangeID: string) => {
    const res = await ExchangesAPI.getOneExchange(exchangeID);
    if (res.status === 200) {
      if (res.data) {
        this.oneClaimData = res.data.claimOne;
        this.responseGetOneClaimDataField = JSON.stringify(res.data.claimOne, null, 2);
      }
    }
  };

  addComment = async (props: postAddCommentToClaimProps) => {
    const res = await ExchangesAPI.addCommentToClaim(props);
    if (res.isSuccess) {
      await this.getOneExchange(props.claimId);
    } else {
      return Error(res.errorData);
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ExchangesVM;
