import { API } from '..';
import { CoinDataResponse, getCoinsParams } from './classes';

const PATHS = {
  COINS: '/api/coins',
  // COINS_ID: (id: string) => `${PATHS.COINS}/${id}`,
};

export const CoinsAPI = {
  getCoins: async (props: getCoinsParams) => {
    return await API.get<CoinDataResponse>({
      url: PATHS.COINS,
      params: {
        ...props,
      },
    });
  },
  // createAdress: async (data: ExToolsDataType) => {
  //   return await API.post({
  //     url: PATHS.EXTOOLS,
  //     data: { ...data },
  //   });
  // },

  //   switchStateAdress: async (id: string, data: ExToolsDataType) => {
  //     return await API.put({
  //       url: PATHS.ADRESS_ID(id),
  //       data: { ...data },
  //     });
  //   },
};
