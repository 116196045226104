import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { InputWrapper } from '../../../../common/components/InputWrapper';
import { ActionTypes, FiltersType } from '../../filterReducer';
import { useModulesContext } from '../../../../store/ModulesContextProvider';
import { SelectFilterTest } from '../../../../common/components/SelectFilterTest';

type Props = {
  filters: FiltersType;
  onFilter: (value: string, key: ActionTypes) => void;
};

const View = ({ filters, onFilter }: Props) => {
  const { CoinsStore, CoinNetworkStore } = useModulesContext();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    CoinsStore.getCoins({ searchValue: searchValue !== '' ? searchValue : undefined });
    CoinNetworkStore.getCoinNetworks({ searchValue: searchValue !== '' ? searchValue : undefined });
  }, [CoinsStore, CoinNetworkStore, searchValue]);

  function handleSearch(value: string) {
    setSearchValue(value);
  }

  function handleSelectCoinFrom(value: string) {
    onFilter(value, 'coinFromId');
  }

  function handleSelectCoinTo(value: string) {
    onFilter(value, 'coinToId');
  }

  function handleSelectNetworkFrom(value: string) {
    onFilter(value, 'networkFromId');
  }

  function handleSelectNetworkTo(value: string) {
    onFilter(value, 'networkToId');
  }

  function handleSelectProvider(value: string) {
    onFilter(value, 'providerName');
  }
  function handleSelectRateType(value: string) {
    onFilter(value, 'rateType');
  }

  const coinData = CoinsStore.data.map((el) => ({ label: el.ticker, value: el.id }));
  const networkData = CoinNetworkStore.data.map((el) => ({ label: el.ticker, value: el.id }));
  const providers = ['CHANGELLY', 'LETS_EXCHANGE'].map((el) => ({ label: el, value: el }));
  const rateTypes = ['MARKET', 'FIXED'].map((el) => ({ label: el, value: el }));

  return (
    <Flex vertical gap={10}>
      <GrayWrap width="1150px">
        <Flex justify="start" gap={10}>
          <InputWrapper titleName="Монета инструмента 1">
            <SelectFilterTest
              onSearch={handleSearch}
              options={coinData}
              selectedValue={filters.coinFromId}
              onSelect={handleSelectCoinFrom}
            />
          </InputWrapper>
          <InputWrapper titleName="Монета инструмента 2">
            <SelectFilterTest
              onSearch={handleSearch}
              options={coinData}
              selectedValue={filters.coinToId}
              onSelect={handleSelectCoinTo}
            />
          </InputWrapper>
          <InputWrapper titleName="Сеть инструмента 1">
            <SelectFilterTest
              onSearch={handleSearch}
              options={networkData}
              selectedValue={filters.networkFromId}
              onSelect={handleSelectNetworkFrom}
            />
          </InputWrapper>
          <InputWrapper titleName="Сеть инструмента 2">
            <SelectFilterTest
              onSearch={handleSearch}
              options={networkData}
              selectedValue={filters.networkToId}
              onSelect={handleSelectNetworkTo}
            />
          </InputWrapper>
          <InputWrapper titleName="Provider">
            <SelectFilterTest
              options={providers}
              selectedValue={filters.providerName}
              onSelect={handleSelectProvider}
            />
          </InputWrapper>
          <InputWrapper titleName="RateType">
            <SelectFilterTest options={rateTypes} selectedValue={filters.rateType} onSelect={handleSelectRateType} />
          </InputWrapper>
        </Flex>
      </GrayWrap>
    </Flex>
  );
};

export const FilterBar = observer(View);