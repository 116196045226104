import { makeAutoObservable } from 'mobx';
import { ExToolsAPI } from '../../api/exTools-api/api';
import { ExToolOneDataType, ExToolsDataType, getExToolsParams } from '../../api/exTools-api/classes';

class ExToolsVM {
  data: ExToolsDataType[] = [];
  count: number = 0;

  toolData: ExToolOneDataType = {} as ExToolOneDataType;

  getExTools = async (props: getExToolsParams) => {
    const res = await ExToolsAPI.getExTools(props);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  getExtoolById = async (id: string) => {
    const res = await ExToolsAPI.getExToolByID(id);
    console.log(res.data);
    if (res.status === 200) {
      if (res.data) {
        this.toolData = res.data.instrumentOne;
        console.log(this.toolData);
      }
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ExToolsVM;
