import { TableColumnsType, Flex, Typography, Switch } from 'antd';
import { SettingDataType, ToggleSettingsProps } from '../../api/generalSettings-api/classes';

type Props = {
  toggleSettingKey: (props: ToggleSettingsProps) => Promise<void>;
};

export const columns = ({ toggleSettingKey }: Props): TableColumnsType<SettingDataType> => {
  return [
    {
      title: 'Key',
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px' }}>{record.setting_key}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'Вкл/выкл',
      render: (_: any, record: SettingDataType) => (
        <Switch
          checkedChildren="ВКЛ"
          unCheckedChildren="ВЫКЛ"
          defaultChecked
          checked={record.is_active}
          onClick={() => toggleSettingKey({ setting_key: record.setting_key, is_active: !record.is_active })}
        />
      ),
    },
  ];
};
