import { observer } from 'mobx-react-lite';
import { Flex, Typography } from 'antd';

import style from '../../style.module.scss';

import { GrayWrap } from '../../../../../../common/components/GrayWrap';
import { InfoElement } from '../common/InfoElement';
import { useModulesContext } from '../../../../../../store/ModulesContextProvider';

const View = () => {
  const { ExchangesStore } = useModulesContext();

  const { changelly_claim, exchange_pair } = ExchangesStore.oneClaimData;

  return (
    <Flex className={style.info_section}>
      <GrayWrap>
        <Typography className={style.info_block_title}>Ввод</Typography>
        <Flex className={style.info__raw}>
          <InfoElement title="Монета" value={`${exchange_pair.instrument_from.coin.ticker}`} />
          <InfoElement title="Сеть" value={`${exchange_pair.instrument_from.network.ticker}`} />
        </Flex>
        <InfoElement
          title="Адрес"
          value={changelly_claim?.payin_address ? `${changelly_claim.payin_address}` : 'No Data'}
        />
        <InfoElement
          title="Memo (extra_id)"
          value={changelly_claim && changelly_claim.payin_extra_id !== '' ? changelly_claim.payin_extra_id : 'No Data'}
        />
      </GrayWrap>

      <GrayWrap>
        <div className={style.details__title}>
          <Typography className={style.info_block_title}>Вывод</Typography>
        </div>
        <Flex className={style.info__raw}>
          <InfoElement title="Монета" value={`${exchange_pair.instrument_to.coin.ticker}`} />
          <InfoElement title="Сеть" value={`${exchange_pair.instrument_to.network.ticker}`} />
        </Flex>
        <InfoElement
          title="Адрес"
          value={changelly_claim && changelly_claim?.payout_address !== '' ? changelly_claim.payout_address : 'No Data'}
        />
        <InfoElement
          title="Memo (extra_id)"
          value={changelly_claim && changelly_claim?.payout_extra_id !== '' ? changelly_claim.payout_extra_id : 'None'}
        />
      </GrayWrap>
      {changelly_claim && changelly_claim.refund_hash !== '' && (
        <GrayWrap>
          <div className={style.details__title}>
            <Typography className={style.info_block_title}>Возврат</Typography>
          </div>
          <Flex className={style.info__raw}>
            <InfoElement title="Монета" value={`${exchange_pair.instrument_to.coin.ticker}`} />
            <InfoElement title="Сеть" value={`${exchange_pair.instrument_to.network.ticker}`} />
          </Flex>
          <InfoElement title="Адрес" value={`${changelly_claim.refund_address}`} />
          <InfoElement
            title="Memo (extra_id)"
            value={`${changelly_claim.refund_extra_id === '' ? 'None' : changelly_claim.refund_extra_id}`}
          />
        </GrayWrap>
      )}
    </Flex>
  );
};

export const ExInOutDetails = observer(View);