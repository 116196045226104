import { TableColumnsType, Typography, Flex, Tooltip, Button } from 'antd';

import { ClearingDataType } from '../../api/clearing-api/classes';

import style from './styles/style.module.scss';
import { Link } from 'react-router-dom';
import { TProviders, TRateTypes } from '../../common/types';
import { BarChartOutlined } from '@ant-design/icons';

type TFilters = {
  providerName: TProviders | undefined;
  rateType: TRateTypes | undefined;
  dateFrom: string;
  dateTo: string;
};

export type TClearingFilters = {
  dateFrom: string;
  dateTo: string;
  coinFromId: string;
  coinToId: string;
  networkFromId: string;
  networkToId: string;
  providerName: TProviders | undefined;
  rateType: TRateTypes | undefined;
};

// todo - get more fields then typed in TFilters
export const columns = (filters: TFilters): TableColumnsType<ClearingDataType> => [
  {
    title: 'Обменная пара',
    render: (_: any, record: ClearingDataType) => {
      const instrumentFrom = `${record.instrumentFrom.coin.ticker}_${record.instrumentFrom.network.ticker}`;
      const instrumentTo = `${record.instrumentTo.coin.ticker}_${record.instrumentTo.network.ticker}`;
      return (
        <Tooltip title={instrumentFrom + ' -' + instrumentTo}>
          <Flex style={{ gap: '8px', zIndex: '1000', opacity: 1 }}>
            <div>
              <Flex>
                <img
                  className={style.crypto_icon}
                  src={record.instrumentFrom.image_url}
                  alt={record.instrumentFrom.name}
                />
                <Typography style={{ marginLeft: '5px' }}>{instrumentFrom}</Typography>
              </Flex>
            </div>
            <Typography>{'-'}</Typography>
            <div>
              <Flex>
                <img className={style.crypto_icon} src={record.instrumentTo.image_url} alt={record.instrumentTo.name} />
                <Typography style={{ marginLeft: '5px' }}>{instrumentTo}</Typography>
              </Flex>
            </div>
          </Flex>
        </Tooltip>
      );
    },
  },
  {
    title: 'Count',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Flex justify="space-between" align="center">
          <Typography>{record.amountData.countTotal}</Typography>
          <Link
            to="/exchanges"
            state={{
              filters: {
                ...filters,
                coinFromId: record.instrumentFrom.coin.id,
                networkFromId: record.instrumentFrom.network.id,
                coinToId: record.instrumentTo.coin.id,
                networkToId: record.instrumentTo.network.id,
              },
            }}
          >
            <Button icon={<BarChartOutlined />} />
          </Link>
        </Flex>
      </>
    ),
  },
  {
    title: 'countFix',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.countFix}</Typography>
      </>
    ),
  },
  {
    title: 'countMarket',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.countMarket}</Typography>
      </>
    ),
  },
  {
    title: 'Sum From',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountFrom}</Typography>
      </>
    ),
  },
  {
    title: 'Sum To',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountTo}</Typography>
      </>
    ),
  },
  {
    title: 'Sum Expected From',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountExpectedFrom}</Typography>
      </>
    ),
  },
  {
    title: 'Sum Expected To',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountExpectedTo}</Typography>
      </>
    ),
  },
  {
    title: 'Комиссия наша',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountFeeOur}</Typography>
      </>
    ),
  },
  {
    title: 'Комиссия провайдера',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountFeeExternal}</Typography>
      </>
    ),
  },
  {
    title: 'Комиссия сети',
    render: (_: any, record: ClearingDataType) => (
      <>
        <Typography>{record.amountData.amountFeeNetwork}</Typography>
      </>
    ),
  },
];
