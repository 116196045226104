import { makeAutoObservable } from 'mobx';
import { ExPairsDataType, getExPairsParams } from '../../api/exchangePair-api/classes';
import { ExPairsAPI } from '../../api/exchangePair-api/api';

class ExchangePairVM {
  data: ExPairsDataType[] = [];
  count: number = 0;

  getData = async (props: getExPairsParams) => {    
    const res = await ExPairsAPI.getExPairs(props);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ExchangePairVM;
