import { observer } from 'mobx-react-lite';
import { Select, Typography } from 'antd';

import { claimStatusString } from '../../../api/exchanges-api/classes';

type Props = {
  titleName: string;
  selectedValue: string | undefined;
  onFilter: (value: string) => void;
};

const View = ({ selectedValue, titleName, onFilter }: Props) => {
  const onChange = (value: string) => {
    const stringValue = value[0];
    onFilter(stringValue);
  };

  // todo - task - what do this funcktion ?
  // Filter `option.label` match the user type `input`

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <Typography>{titleName}</Typography>
      <Select
        style={{ width: '180px' }}
        mode="multiple"
        maxCount={1}
        showSearch
        onChange={onChange}
        filterOption={filterOption}
        options={claimStatusString?.map((item) => ({ label: item, value: item }))}
        value={selectedValue !== '' ? selectedValue : undefined}
        optionFilterProp="status"
      />
    </div>
  );
};

export const SelectClaimStatus = observer(View);
