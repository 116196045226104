import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { WrapperSelectFilter } from '../WrapperSelectFilter';
import { SearchField } from '../../../../common/components/SearchField';
import { FiltersType, IActionTypes } from '../../filterReducer';
import { SelectClaimStatus } from '../../../../common/components/SelectClaimStatus';

import { useModulesContext } from '../../../../store/ModulesContextProvider';
import { providers } from '../../../../common/types';

type Props = {
  filters: FiltersType;
  onFilter: (value: string, key: IActionTypes) => void;
  onSearchFilter: (value: string) => void;
  onStatusFilter: (value: string) => void;
};

const View = ({ filters, onFilter, onSearchFilter, onStatusFilter }: Props) => {

  const { CoinsStore, CoinNetworkStore } = useModulesContext();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    CoinsStore.getCoins({ searchValue: searchValue !== '' ? searchValue : undefined });
    CoinNetworkStore.getCoinNetworks({ searchValue: searchValue !== '' ? searchValue : undefined });
  }, [CoinsStore, CoinNetworkStore, searchValue]);

  function handleSearch(value: string) {
    setSearchValue(value);
  }

  const coinData = CoinsStore.data.map((el) => ({ label: el.ticker, value: el.id }));
  const networkData = CoinNetworkStore.data.map((el) => ({ label: el.ticker, value: el.id }));
  const providerData = providers.map((el) => ({ label: el, value: el }));

  return (
    <Flex vertical gap={10}>
      <GrayWrap width="1150px">
        <Flex justify="space-between">
          <SearchField fieldName="Поиск" searchValue={filters.searchValue} setSearchValue={onSearchFilter} />
          <WrapperSelectFilter
            titleName="Монета 1|2"
            actionType="coinId"
            selectedValue={filters.coinId}
            onFilter={onFilter}
            options={coinData}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Монета инструмента 1"
            actionType="coinFromId"
            selectedValue={filters.coinFromId}
            options={coinData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Монета инструмента 2"
            actionType="coinToId"
            selectedValue={filters.coinToId}
            options={coinData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />

          <WrapperSelectFilter
            titleName="Сеть 1|2"
            actionType="networkId"
            selectedValue={filters.networkId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Сеть инструмента 1"
            actionType="networkFromId"
            selectedValue={filters.networkFromId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <WrapperSelectFilter
            titleName="Сеть инструмента 2"
            actionType="networkToId"
            selectedValue={filters.networkToId}
            options={networkData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
        </Flex>
        <Flex gap={10}>
          <WrapperSelectFilter
            titleName="Провайдер"
            actionType="providerName"
            selectedValue={filters.providerName}
            options={providerData}
            onFilter={onFilter}
            onSearch={handleSearch}
          />
          <SelectClaimStatus titleName="Статус обмена" selectedValue={undefined} onFilter={onStatusFilter} />
        </Flex>
      </GrayWrap>
    </Flex>
  );
};

export const FilterBar = observer(View);